<template>
  <div
    class="road-map-component"
    :class="$store.state.mobileView ? 'mobile' : 'desktop'"
  >
    <img
      class="clipboard"
      v-if="!$store.state.mobileView"
      src="@/assets/backgrounds/roadmap.png"
    />
    <div class="road-map">
      <div
        class="top-text"
        :class="$store.state.mobileView ? 'mobile' : 'desktop'"
      >
        {{ roundInfo.title[0] }} - <br />
        {{ roundInfo.title[1] }}
      </div>
      <div class="round-instructions">
        <input
          class="audio"
          type="image"
          v-on:click="$store.dispatch('playVO', roundInfo.audio)"
          :src="require('../assets/play.png')"
          alt="play sound"
        />
        {{ roundInfo.instructions }}
      </div>
    </div>
    <div
      class="bottom-row"
      :class="$store.state.mobileView ? 'mobile' : 'desktop'"
    >
      <div
        class="sports"
        :style="{
          backgroundImage:
            'url(' + require('@/assets/backgrounds/' + roadmapImage) + ')',
        }"
      ></div>
      <button class="road-map-button" v-on:click="nextRound">
        {{ buttonText }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "RoadMap",
  data() {
    return {
      buttonText: "",
    };
  },
  mounted() {
    this.$store.commit("getFocusableItems");
    if (this.$store.state.audio) {
      this.$store.dispatch("playVO", this.roundInfo.audio);
    }
    this.buttonText = "Game On";
  },
  methods: {
    nextRound() {
      window.dispatchEvent(new Event("resize"));
      this.$store.commit("stopAllSounds");
      //   this.$store.state.showRoundFeedback = false;
      //   this.$store.state.roundCounter += 1;
      //   this.$store.state.decisionCounter = 1;
      //   this.$store.state.setupCounter = 1;
      //   this.$store.state.resultCounter = 1;
      //   this.$store.state.roundScore = 0;
      //   this.$store.state.nextButtonText = "Next Question";
      //   this.$store.state.showRewardModal = false;
      //   this.$store.state.showQuestion = false;
      //   this.$store.state.showFeedback = false;
      //   this.$store.state.showResults = false;
      //   this.$store.state.correct = false;
      //   this.$store.state.results = {};
      if (
        this.$store.state.roundCounter <=
        Object.keys(this.$store.state.content).length
      ) {
        //   this.$store.state.roundCounter += 1;
        this.$store.commit("navigate", { route: "Dialogue", data: "" });
      } else {
        this.$store.commit("navigate", { route: "Outcome", data: "" });
      }
    },
  },
  computed: {
    roadmapImage: function () {
      return (
        "roadmap-" +
        (this.$store.state.mobileView ? "mobile-round" : "desktop-round") +
        this.$store.state.roundCounter +
        ".png"
      );
    },
    roundInfo: function () {
      let roundInfo = {};
      roundInfo.interaction =
        this.$store.state.content[
          "round" + this.$store.state.roundCounter
        ].info.title.interaction;
      roundInfo.audio =
        this.$store.state.content[
          "round" + this.$store.state.roundCounter
        ].info.title.audio;
      roundInfo.title =
        this.$store.state.content[
          "round" + this.$store.state.roundCounter
        ].info.title.character.split("-");
      roundInfo.instructions =
        this.$store.state.content[
          "round" + this.$store.state.roundCounter
        ].info.title.text;
      return roundInfo;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.road-map-component {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-rows: minmax(0, 3fr) minmax(0, 7fr);
  grid-template-columns: minmax(0, 1fr);
  justify-content: center;
  align-items: flex-start;
  background-color: var(--off-white);
  position: relative;
  overflow: hidden;
}

.road-map-component.mobile {
  grid-template-rows: minmax(0, 2fr) minmax(0, 8fr);
}

.top-text {
  font-size: inherit;
}

.bottom-row {
  display: grid;
  padding-left: 30vw;
  grid-template-rows: 5fr 2fr;
  grid-template-columns: 1fr;
  height: 100%;
  width: 100%;
  overflow: hidden;
  align-items: center;
  align-content: center;
  justify-content: center;
  box-sizing: border-box;
  justify-items: center;
}

.bottom-row.mobile {
  padding: 0;
  grid-template-rows: 10fr 2fr;
}

.clipboard {
  position: absolute;
  left: 0;
  bottom: 0;
  max-width: 30vw;
  max-height: 85%;
  z-index: 0;
}

.road-map {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-size: clamp(12px, 4vw, 4vh);
  grid-row: 1;
  align-items: flex-start;
  justify-content: space-evenly;
  color: var(--off-white);
  text-align: left;
  font-weight: bold;
  background-color: var(--top-bar-blue);
  padding-left: 30vw;
  box-sizing: border-box;
}

.mobile > .road-map {
  font-size: clamp(12px, 5vw, 4vh);
}

.road-map-button {
  background-color: var(--light-red);
  color: var(--text-blue);
  font-weight: bold;
  font-size: clamp(12px, 3vw, 3vh);
  font-family: inherit;
  border: none;
  border-radius: 60px;
  padding: 5px 25px;
  box-shadow: 0px 7px 0px var(--dark-red);
  z-index: 1;
}

.round-instructions {
  font-size: clamp(12px, 3vw, 3vh);
  display: flex;
  align-items: center;
  z-index: 1;
}

.mobile > * .round-instructions {
  font-size: clamp(12px, 4vw, 3vh);
}

.bottom-row.mobile > .road-map-button {
  font-size: clamp(12px, 4vw, 3vh);
}

.bottom-row > .sports {
  width: calc(100% - 120px);
  height: calc(100% - 120px);
  background-repeat: no-repeat;
  background-size: contain;
  margin: 60px;
  background-position: center;
}

.bottom-row.mobile > .sports {
  height: calc(100% - 6vh);
  width: 100%;
  margin: 3vh;
}
</style>
